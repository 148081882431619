import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const EmploymentPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-4.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Employment</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>At Habitat for Humanity, we embrace a history rooted in creating equity, and commit to a culture and workplace where all staff feel safe, welcome, respected, and valued.</h2>
					<p>
						Across Habitat’s many networks, we’re working together to support the life-changing mission of
						connecting families and communities with the transformational impact of decent, affordable shelter. As
						an equal opportunity employer, we encourage people of all races, ethnicities, national origins, tribes,
						religions, ages, genders, sexual orientations, marital statuses, abilities, and socioeconomic statuses to
						work with us.
					</p>
					<h3>Employment opportunities:</h3>
					<p>We occasionally have openings in our ReStore and affiliate office. Openings are posted on Facebook and LinkedIn.</p>
					<h3>How to apply:</h3>
					<p>
					If interested in employment with Habitat for Humanity Northwoods Wisconsin, send an email to <a href="mailto:careers@habitatnorthwoods.org">careers@habitatnorthwoods.org</a>. You will receive a reply with an online appilcation link.
					</p>
					<h3>Ethics and Safeguarding:</h3>
					<p>
						We require that all staff take seriously their ethical responsibilities to safeguard our intended
						beneficiaries, their communities (especially children), and all those with whom we work. In line with the
						prevention of sexual exploitation and abuse, all staff must pass a thorough background screening and
						will be held accountable for upholding our policies around ethical behavior, including safeguarding and
						whistleblowing.
					</p>
					<h3>Benefits:</h3>
					<p>Full-time employees are eligible for the following benefits:</p>
					<ul>
						<li>Market competitive salaries, with ongoing eligibility for increases</li>
						<li>Competitive paid time off programs, including 7 paid holidays</li>
						<li>Career and personal development resources</li>
						<li>Basic Life Insurance</li>
						<li>Accidental Death and Dismemberment</li>
						<li>Long-term Disability</li>
						<li>Short-term Disability</li>
						<li>Simple IRA</li>
					</ul>
					<p>
						All qualified applicants will receive consideration for employment without regard to age, race, color,
						religion, sexual orientation, or national origin. EEO/AA Employer/Vets/Disability
					</p>
					<p>
						Habitat ReStore, 908 Lincoln St Rhinelander, WI 54501 <a href="tel:(715) 420-2301">(715) 420-2301</a> <br />
						Habitat Office, 908 Lincoln St Rhinelander, WI 54501 <a href="tel:(715) 420-0412">(715) 420-0412</a>
					</p>
					<p>
						Habitat for Humanity Northwoods Wisconsin serving Oneida and Vilas counties is an independent
						organization that is overseen by a board of directors and governed by its own policies. If you have a
						concern about Habitat Northwoods Wisconsin, please contact us at <a href="tel:(715) 420-0412">(715) 420-0412</a> to discuss the issue.
					</p>
					<p>
						If you believe your concern cannot be adequately addressed or resolved by the affiliate, or if your
						concern relates to the board president or executive director, please notify Habitat for Humanity
						International by following the process outlined on <a href="https://www.habitat.org/" target="_blank" rel="noopener noreferrer">Habitat.org</a>.
					</p>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default EmploymentPage

export const Head = () => (
	<Seo
        title="Employment | Habitat For Humanity Northwoods Wisconsin"
    />
)